import * as Constants from "../constants";
import Moment from "moment";
import { isValidDate } from "./validator";

export const updateDateRangeFilter = (
	startDate,
	endDate,
	fromDateFieldName,
	toDateFieldName,
	setFieldValue
) => {
	const dateFormat = Constants.DateFullYear;
	let fromDate = isValidDate(startDate)
		? Moment(startDate).format(dateFormat)
		: null;

	let toDate = isValidDate(endDate)
		? Moment(endDate).format(dateFormat)
		: fromDate;

	let dateSearchCondition = !fromDate && !toDate ? '' : `${fromDate} - ${toDate}`;
	// when dates are the same - show only one date, but pass 2 dates to the API
	if (Moment(startDate).isSame(endDate)) {
		dateSearchCondition = fromDate;
		toDate = Moment(fromDate, dateFormat).add(1, "days").format(dateFormat);
	}
	setFieldValue("dateSearchCondition", dateSearchCondition);

	// helper fields for API call, hidden from user
	setFieldValue(fromDateFieldName, fromDate);
	setFieldValue(toDateFieldName, toDate);
};
