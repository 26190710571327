import Moment from "moment";

export const CalendarDateRegEx = new RegExp(/^\d{4}-\d{2}-\d{2}$/);

export const DateSearchFormat = "YYYY-MM-DD";

export const DateAndTimeFullFormat = "DD-MM-YYYY, HH:mm:ss";

export const DateAndTimeShortYearWithoutSecFormat = "DD-MM-YY, HH:mm";

export const DateAndTimeShortYearFormat = "DD-MM-YY, HH:mm:ss";

export const DateAndTimeMonthAndDayFormat = "DD-MM, HH:mm";

export const DateFullYear = "DD-MM-YYYY";

export const DateShortYear = "DD-MM-YY";

export const DayAndMonthFormat = "DD-MM";

export const DateFullTime = "HH:mm:ss";

export const DateShortTime = "HH:mm";

export const DayAndTimeDateFormat = "llll";

export const CreatedAtMinDate = new Date(2012, 2, 11);

// Define the supported timezones
export const TIMEZONES = {
	UTC: "UTC",
	CET: "CET",
	// Add more timezones as needed
};

// based on the assumption with 3-year storage policy
export const EndDateRidesSearch = Moment().add(3, "years").toDate();
