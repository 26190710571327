/* if there is date parameter was selected in the calendar send it as an obj with "at" key, or fromDate and toDate for the date range */
import { isValidFormattedDate } from "./validator";
import * as Constants from "../constants";
import Moment from "moment";

export const formatDateSearchCondition = (fromDate, toDate) => {
	if (!fromDate && !toDate) {
		return null;
	}

	let dateSearchCondition;

	// if both dates were selected
	if (fromDate && toDate) {
		let from = Moment(fromDate, Constants.DateFullYear).format(
			Constants.DateSearchFormat
		);
		let to = Moment(toDate, Constants.DateFullYear).format(
			Constants.DateSearchFormat
		);

		if (isValidFormattedDate(from) && isValidFormattedDate(to)) {
			// if the dates are the same - search for the exact date
			if (Moment(from).isSame(to)) {
				dateSearchCondition = {
					at: from,
				};
			} else {
				// if the dates are different - search for the range
				dateSearchCondition = {
					[Constants.OrderFilters.fromDate]: from,
					[Constants.OrderFilters.toDate]: to,
				};
			}
		} else {
			dateSearchCondition = null;
		}
	}

	// if only one date was selected
	if ((fromDate && !toDate) || (!fromDate && toDate)) {
		let date = fromDate || toDate;
		date = Moment(date, Constants.DateFullYear).format(
			Constants.DateSearchFormat
		);

		if (isValidFormattedDate(date)) {
			dateSearchCondition = {
				at: date,
			};
		} else {
			dateSearchCondition = null;
		}
	}

	return dateSearchCondition;
};
