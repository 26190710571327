/* component receives an object with input values from a search form */

import { Grid, GridCol, Tag } from "@flixbus/honeycomb-react";
import { OrderFilters, RideFilters } from "../../constants/FieldNames";
import "./FiltersBar.scss";

export const FiltersBar = ({ filters, onFilterDelete, onClearFilters }) => {
	const isEmptyFilters = () => {
		if (!filters) {
			return true;
		} else {
			// check if all values in filters object are empty
			return Object.values(filters).every((field) => {
				if (field === null || field === "") {
					return true;
				} else if (Array.isArray(field)) {
					return !field.length;
				}
			});
		}
	};

	return isEmptyFilters() ? null : (
		<div className="FiltersBar">
			<Grid>
				{filters &&
					Object.keys(filters).map((keyName, i) => {
						// don't need to show lineID, fromDate, toDate - it's hidden values for API call
						if (
							keyName == RideFilters.lineIds ||
							keyName == OrderFilters.fromDate ||
							keyName == OrderFilters.toDate ||
							keyName == RideFilters.fromDateTime ||
							keyName == RideFilters.toDateTime
						) {
							return;
						} else {
							// in case when filter parameter has multiple values, e.g. weekdays, statuses
							if (Array.isArray(filters[keyName])) {
								return filters[keyName].map((filterValue, k) => {
									return (
										!!filterValue && (
											<GridCol key={k.toString()} inline>
												<Tag
													extraClasses="FilterTag"
													display="outlined"
													closeProps={{
														label: "Close tag",
														onClick: () => onFilterDelete(keyName, k),
													}}
												>
													{filterValue}
												</Tag>
											</GridCol>
										)
									);
								});
							} else {
								return (
									!!filters[keyName] && (
										<GridCol key={keyName.toString()} inline>
											<Tag
												extraClasses="FilterTag"
												display="outlined"
												closeProps={{
													label: "Close tag",
													onClick: () => onFilterDelete(keyName, i),
												}}
											>
												{filters[keyName]}
											</Tag>
										</GridCol>
									)
								);
							}
						}
					})}
				<GridCol md={2} sm={6}>
					{filters && !isEmptyFilters() && (
						<Tag
							extraClasses="my-1"
							role="button"
							onClick={() => onClearFilters()}
						>
							Reset all
						</Tag>
					)}
				</GridCol>
			</Grid>
		</div>
	);
};
